import React from "react"
import styled from "@emotion/styled"
import banner from "../images/dinoalastorres.png"
import Navigation from "./navigation"
import { Link } from "gatsby"
import LanguageSwitch from "./lang-switch"

export default ({ className, lang, pathEn, pathEs, currentPath }) => (
  <Header className={className}>
    <StyledLink to="/"/>
    <Content>
      <Navigation lang={lang} currentPath={currentPath}/>
    </Content>
    <LanguageSwitch lang={lang} pathEn={pathEn} pathEs={pathEs}/>
  </Header>
)

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
   margin: 0;
  }
`

const StyledLink = styled(Link)`
    background-image: url(${banner});
    background-repeat: no-repeat;
    width: 267px;
    height: 48px;
    background-size: contain;
    background-position: center;
`
const Header = styled.header`
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #343a40;
  font-family: 'Archivo Black';
  position: sticky;
  @media (max-width: 600px) {
    position: inherit;
  }
  z-index: 999;
  top: 0;
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  align-content: center;
  align-items: center;
  ${Navigation} {
    margin-left: 24px;
    max-width: 100%;
    @media (max-width: 800px) {
      margin-left: 0;
      width: 100%;
      max-width: inherit;
      padding: 0 8px 0 8px;
    }
  }
  ${LanguageSwitch} {
    position: absolute;
    top: 0;
    right: 8px;
    height: 48px;
  }
`
