import React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery, Link } from "gatsby"

const ensureSlashPostfix = path => `${path}//`.replace("//", "/").replace("//", "/")
const PageItem = styled(({ id, path, currentPath, title, className }) => {
  const to = ensureSlashPostfix(path)
  console.log('PageItem', path, title, to)
  return (
    <Link
      className={className}
      to={to}
      activeStyle={{ color: "red", pointerEvents: "none" }}
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {title}
    </Link>
  )
})``

const Navigation = ({ currentPath, className, lang }) => {
  const data = useStaticQuery(query)

  let nodes = data.allMarkdownRemark.nodes
    .filter(node => node.frontmatter.type === "page")
    .filter(node => node.frontmatter.lang === lang)

  nodes = nodes.sort((n1, n2) => {
    return n1.frontmatter.order > n2.frontmatter.order
  })

  return (
    <Nav className={className}>
      <ul>
        {nodes.map(node => (
          <PageItem
            key={node.id}
            path={node.frontmatter.path}
            currentPath={currentPath}
            title={node.frontmatter.title}
          />
        ))}
        <PageItem
          path={{ es: "/actividades", en: "/activities" }[lang]}
          title={{ es: "Actividades", en: "Activities" }[lang]}
          currentPath={currentPath}
        />
        <PageItem
          path={{ es: "/blog", en: "/blog-en" }[lang]}
          title={{ es: "Blog", en: "Blog" }[lang]}
          currentPath={currentPath}
        />
        <PageItem
          path={{ es: "/documentos", en: "/documents" }[lang]}
          title={{ es: "Documentos", en: "Documents" }[lang]}
          currentPath={currentPath}
        />
      </ul>
    </Nav>
  )
}

const Nav = styled.nav`
  ul {
    font-size: 17px;
    @media (max-width: 1300px) {
      font-size: 14px;
    }
    @media (max-width: 1000px) {
      font-size: 12px;
    }
    @media (max-width: 950px) {
      font-size: 10px;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      height: auto;
      ${PageItem} {
        margin-left: 0;
        padding: 18px 0;
      }
    }

    background: transparent;
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    height: 48px;
    align-items: center;
    justify-content: space-between;
  }
  ${PageItem} {
    &:not(:first-of-type) {
      margin-left: 24px;
      @media (max-width: 900px) {
        margin-left: 12px;
      }
    }
  }
  a {
    color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
    height: 100%;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
`

const query = graphql`
  query SiteTitleQuery {
    allMarkdownRemark {
      nodes {
        id
        frontmatter {
          title
          path
          order
          lang
          type
        }
      }
    }
  }
`

export default styled(Navigation)``
