import React from "react"
import flagGB from "./flag-gb.png"
import flagES from "./flag-es.png"
import styled from "@emotion/styled"

const showAll = true

const LanguageSwitch = ({ className, lang, pathEn, pathEs }) => (
  <Root className={className}>
    {
      lang !== "es" || showAll ? <ES href={pathEs} active={lang === "en"}/> : null
    }
    {
      lang !== "en" || showAll ? <EN href={pathEn} active={lang === "es"}/> : null
    }
  </Root>
)


const BTN = styled.a`
  width: 40px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: ${props => props.active ? 1 : 0.3};
  @media(max-width: 1000px) {
    width: 32px;
    height: 24px;
  }
  @media(max-width: 600px) {
    width: 24px;
    height: 18px;
  }
  @media(max-width: 500px) {
    display: ${props => !props.active ? 'none' : null};
  }
`

const ES = styled(BTN)`
  background-image: url(${flagES});
`
const EN = styled(BTN)`
  background-image: url(${flagGB});

`
const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export default styled(LanguageSwitch)``
