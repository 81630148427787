import * as React from "react"

type LanguageContextValue = string

const ctx: React.Context<LanguageContextValue> = React.createContext<
  LanguageContextValue
>("es")
const Provider = ctx.Provider
const Consumer = ctx.Provider

const useLanguage = () => React.useContext<LanguageContextValue>(ctx)
export {
  Provider as LanguageProvider,
  Consumer as LanguageConsumer,
  useLanguage,
}
