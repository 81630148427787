import React from "react"
import Header from "./header"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import Helmet from "react-helmet"
import "./layout.css"
import almendral from "../images/almendral-alpujarra.jpg"
import {LanguageProvider} from "../LanguageContext"

const Layout = ({ children, lang, pathEn, pathEs, currentPath }) => {
  const isRoot = currentPath === "/" || currentPath === "/start"

  return (
    <LanguageProvider value={lang}>
      <Root>
        <Global
          styles={css`
            @import url("https://fonts.googleapis.com/css?family=Alfa+Slab+One|Archivo+Black|Livvic&display=swap");
            body {
              background-position: center top;
              background-size: auto;
              background-repeat: repeat;
              background-attachment: fixed;
              font-family: "Georgia", "Times", serif;
              color: rgba(0, 0, 0, 0.9);
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-family: Abril Fatface, cursive, BlinkMacSystemFont, Segoe UI,
                Roboto, Oxygen;
            }
          `}
        />
        <Helmet>
          <link rel="icon" href="/static/favicon.ico" />
        </Helmet>
        <SHeader
          siteTitle="Di No A Las Torres de alta tension en la Alpujarra"
          currentPath={currentPath}
          lang={lang}
          pathEn={pathEn}
          pathEs={pathEs}
        />
        {isRoot ? (
          <RootMainImage>
            <img alt="Paisaje bonito de la Alpujarra" src={almendral} />
          </RootMainImage>
        ) : null}

        <main>
          {isRoot ? null : (
            <NonRootImage>
              <img alt="Paisaje bonito de la Alpujarra" src={almendral} />
            </NonRootImage>
          )}

          {children}
        </main>
        <hr />
        <Footer>
          © {new Date().getFullYear()} PLATAFORMA CIUDADANA – DI NO A LAS TORRES
          DE ALTA TENSIÓN
        </Footer>
      </Root>
    </LanguageProvider>
  )
}
const SHeader = styled(Header)``

const RootMainImage = styled.div`
  margin-bottom: 12px;
`

const NonRootImage = styled.div`
  margin-top: 24px;
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  img {
    width: 540px;
  }
`
const Root = styled.div`
  main {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 12px 24px 12px;
    font-size: 20px;
  }
  .wp-block-file__button {
    background: red;
  }
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`

export default Layout
